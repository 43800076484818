import { IStepRunEventTypeEnum } from '@invisible/concorde/gql-client'
import { useQuery } from '@invisible/trpc/client'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import CachedIcon from '@mui/icons-material/Cached'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import { groupBy } from 'lodash/fp'
import { useEffect, useMemo, useState } from 'react'
import Markdown from 'react-markdown'

import { useStepRunEventLogger } from '../../hooks/useStepRunEventLogger'
import { useRLHFContext } from './context'
import { PromptEdit } from './PromptEdit'
import { PromptResponse } from './Response'

type TBaseRunVariables = NonNullable<
  inferQueryOutput<'baseRun.findChildBaseRuns'>
>[0]['baseRunVariables']

export type TPrompt = {
  id: string
  index: number
  text: string
  createdAt: Date
  responseId?: string
  acceptedResponse?: string
  baseRunVariables: TBaseRunVariables
}

type TProps = {
  prompt: TPrompt
  collapsed?: boolean
}
const Turn = ({ collapsed, prompt }: TProps) => {
  const [isCollapsed, setCollapsed] = useState(collapsed ?? (!!prompt.acceptedResponse || false))
  const [promptEditToggle, setPromptEditToggle] = useState(false)
  const [isEditingResponse, setIsEditingResponse] = useState(false)
  const { maybeLogStepRunEvent } = useStepRunEventLogger()
  const {
    config,
    stepRunId,
    firstManualStepRunCreatedAt,
    numOfPrompts,
    visibleResponseIndices,
    resubmitPrompt,
    deletePrompt,
    refetchPromptResponses,
    isReadOnly,
    loaders: {
      isCreatingManyBaseRuns,
      isCreatingBaseRun,
      isDeletingBaseRuns,
      isUpdatingBaseVariables,
    } = {},
  } = useRLHFContext()

  const { data: activePromptResponses, isLoading: isFetchingResponses } = useQuery(
    [
      'baseRun.findChildBaseRuns',
      {
        baseId: config?.responsesBaseId as string,
        parentBaseRunId: prompt.id as string,
      },
    ],
    {
      enabled: isEditingResponse || !prompt.acceptedResponse,
    }
  )

  const normalizedActivePromptResponses = useMemo(() => {
    const sortedResponses = (activePromptResponses ?? [])
      .map((response) => ({
        id: response.id,
        text: response.baseRunVariables.find(
          (variable) => variable.baseVariable.id === config?.responseTextBaseVariableId
        )?.value as string,
        originalText: response.baseRunVariables.find(
          (variable) => variable.baseVariable.id === config?.responseOriginalTextBaseVariableId
        )?.value as string,
        index: response.baseRunVariables.find(
          (variable) => variable.baseVariable.id === config?.responseIndexBaseVariableId
        )?.value as number,
        model: response.baseRunVariables.find(
          (variable) => variable.baseVariable.id === config?.responseModelBaseVariableId
        )?.value as string,
        rank: response.baseRunVariables.find(
          (variable) => variable.baseVariable.id === config?.responseRankBaseVariableId
        )?.value as number,
        rating: response.baseRunVariables.find(
          (variable) => variable.baseVariable.id === config?.responseRatingBaseVariableId
        )?.value as number,
        baseRunVariables: response.baseRunVariables,
        selected: false,
      }))
      .filter((response) => response.originalText)
      .sort((a, b) => a.index - b.index)
    const groupedResponses = groupBy('model', sortedResponses)
    return { flattened: sortedResponses, grouped: groupedResponses }
  }, [activePromptResponses, config])

  const highestRankedResponseIds = useMemo(() => {
    const selectedResponse = normalizedActivePromptResponses.flattened.reduce(
      (acc, cur) => {
        if (!cur.rank || !cur.rating) return acc

        if (cur.rank < (acc.rank > 0 ? acc.rank : Infinity)) {
          return cur
        }
        if (cur.rank === acc.rank) {
          return cur.rating > acc.rating ? cur : acc
        }
        return acc
      },
      { rank: 0, rating: 0, id: '' }
    )

    const sameRankRatingResponses = normalizedActivePromptResponses.flattened.filter(
      (response) =>
        response.id &&
        selectedResponse?.rating === response.rating &&
        selectedResponse?.rank === response.rank &&
        selectedResponse?.id !== response.id &&
        response.rating !== null &&
        response.rank !== null
    )

    return [
      ...(selectedResponse?.id ? [selectedResponse?.id] : []),
      ...sameRankRatingResponses.map((response) => response.id),
    ]
  }, [normalizedActivePromptResponses])

  useEffect(() => {
    if (collapsed !== undefined && collapsed !== isCollapsed) {
      setCollapsed(collapsed)
    }
  }, [collapsed])

  const promptIndex = prompt.index + 1
  const isLastTurn = promptIndex === numOfPrompts

  const allResponsesData = useMemo(
    () =>
      !isFetchingResponses && normalizedActivePromptResponses?.flattened?.length
        ? normalizedActivePromptResponses.flattened
            .filter((_, index) => (visibleResponseIndices ?? []).includes(index + 1))
            .map((response) => ({ ...response }))
        : [],
    [isFetchingResponses, normalizedActivePromptResponses.flattened, visibleResponseIndices]
  )

  const handleEditPrompt = () => {
    maybeLogStepRunEvent({
      name: 'editing_prompt',
      stepRunId: stepRunId as string,
      spanType: 'TURN',
      spanLocation: promptIndex,
      type: IStepRunEventTypeEnum.Span,
      timestamp: new Date(),
    })
    setPromptEditToggle(!promptEditToggle)
  }

  const handleEditResponse = () => {
    maybeLogStepRunEvent({
      name: 'editing_response',
      stepRunId: stepRunId as string,
      spanType: 'TURN',
      spanLocation: promptIndex,
      type: IStepRunEventTypeEnum.Span,
      timestamp: new Date(),
    })
    setIsEditingResponse(true)
  }

  return (
    <div className='mb-4 w-full'>
      <div
        className='mb-4 flex cursor-pointer items-center'
        onClick={() => {
          setCollapsed(!isCollapsed)
          if (prompt.acceptedResponse) {
            setIsEditingResponse(false)
          }
        }}>
        <span className='mr-2 text-gray-400'>
          {isCollapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </span>
        <div className='bold'>
          {promptIndex}. Turn {promptIndex}
        </div>
      </div>

      {isCollapsed ? null : (
        <div className='ml-8'>
          <div className='mb-2 flex'>
            {promptEditToggle ? (
              <PromptEdit
                baseRunVariables={prompt.baseRunVariables ?? []}
                disableMetadataEdit={config.disablePromptMetadataEdit && !isLastTurn}
                disablePromptEdit={
                  isReadOnly ||
                  !config.allowEditPrompt ||
                  (config.allowEditPrompt && config.allowAllTurnsEditing ? false : !isLastTurn)
                }
                prompt={prompt}
                handleClose={() => setPromptEditToggle(false)}
              />
            ) : (
              <>
                <div
                  className={`relative w-fit min-w-[20%] max-w-[50%] rounded bg-[#E0E7FF] py-1 px-4 text-sm`}>
                  <Markdown
                    className='overflow-auto'
                    components={{
                      p: ({ children }) => <p className='whitespace-pre-wrap'>{children}</p>,
                    }}>
                    {prompt.text}
                  </Markdown>{' '}
                </div>
                <div className='ml-2 mt-2'>
                  <IconButton size='small' onClick={handleEditPrompt} className='text-gray-400'>
                    <ExpandMoreIcon />
                  </IconButton>

                  {config.allowDeletePrompt && !isFetchingResponses && (
                    <IconButton
                      title='Delete'
                      disabled={isDeletingBaseRuns || isReadOnly}
                      onClick={() => deletePrompt(prompt)}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                  {
                    /*
                      The ability to Refetch Responses will only be available to the most recent Prompt at a maximum.
                      We can NEVER refetch Responses for older turns, as ensured by the `isLastTurn` condition below.

                      If disablePromptResponsesRefresh is False && allowPromptResponsesRefreshOnImportedTurns is False,
                      the most recent Prompt will need to be non-imported for Refetch to work (prompt.createdAt > firstManualStepRunCreatedAt).

                      If disablePromptResponsesRefresh is False && allowPromptResponsesRefreshOnImportedTurns is True,
                      the most recent Prompt will have Refetch work even if it is imported.

                      If disablePromptResponsesRefresh is True, allowPromptResponsesRefreshOnImportedTurns will always be False.
                      In this case, Refetch option will not be available even to the most recent Turn.
                    */
                    config.disablePromptResponsesRefresh ? null : (
                      <>
                        {!isFetchingResponses &&
                          isLastTurn &&
                          (prompt.createdAt > firstManualStepRunCreatedAt ||
                            config.allowPromptResponsesRefreshOnImportedTurns) && (
                            <IconButton
                              title='Refetch Response'
                              disabled={
                                isDeletingBaseRuns ||
                                isCreatingManyBaseRuns ||
                                isUpdatingBaseVariables ||
                                isReadOnly
                              }
                              onClick={() => refetchPromptResponses(prompt)}>
                              <CachedIcon />
                            </IconButton>
                          )}

                        {!prompt.acceptedResponse &&
                          !isFetchingResponses &&
                          !isLastTurn &&
                          !(normalizedActivePromptResponses.flattened.length > 0) && (
                            <IconButton
                              title='Resubmit prompt'
                              className='hidden'
                              disabled={
                                isFetchingResponses ||
                                isCreatingManyBaseRuns ||
                                isCreatingBaseRun ||
                                isReadOnly
                              }
                              onClick={() => resubmitPrompt(prompt)}>
                              <CachedIcon />
                            </IconButton>
                          )}
                      </>
                    )
                  }
                </div>
              </>
            )}
          </div>

          {/* Prompt Responses */}
          {isEditingResponse || !prompt.acceptedResponse ? (
            <>
              {prompt.acceptedResponse && (
                <div className='flex justify-end'>
                  <IconButton
                    className=' cursor-pointer text-gray-400'
                    onClick={() => setIsEditingResponse(false)}>
                    <CloseIcon />
                  </IconButton>
                </div>
              )}
              <div className='relative flex w-full gap-2 overflow-auto pt-1' key={prompt.id}>
                {(normalizedActivePromptResponses.flattened ?? []).map((response, index) =>
                  (visibleResponseIndices ?? []).includes(index + 1) ? (
                    <div className=' min-w-[300px] max-w-[600px] flex-1'>
                      <PromptResponse
                        response={{ ...response, index: index + 1 }}
                        activePromptId={prompt.id}
                        activePromptCreatedAt={prompt.createdAt}
                        numOfRank={normalizedActivePromptResponses.flattened.length}
                        key={response.model + response.id}
                        stepRunId={stepRunId as string}
                        baseRunVariables={response.baseRunVariables ?? []}
                        isHighestRanked={highestRankedResponseIds.includes(response.id)}
                        selectedResponseId={prompt.responseId}
                        disableResponseMetadataEdit={
                          config.disableResponseMetadataEdit && !isLastTurn
                        }
                        disableSelectionAndResponseEdit={
                          config.allowAllTurnsEditing ? false : !isLastTurn
                        }
                        handleClose={() => setIsEditingResponse(false)}
                      />
                    </div>
                  ) : null
                )}

                {(normalizedActivePromptResponses.flattened ?? []).filter((_, index) =>
                  (visibleResponseIndices ?? []).includes(index + 1)
                ).length === 0 &&
                  !(
                    isFetchingResponses ||
                    isCreatingManyBaseRuns ||
                    isUpdatingBaseVariables ||
                    isCreatingBaseRun
                  ) && (
                    <div className='text-gray-400'>
                      No responses to show - check Visible Responses or reload responses
                    </div>
                  )}

                {(normalizedActivePromptResponses.flattened ?? []).filter((_, index) =>
                  (visibleResponseIndices ?? []).includes(index + 1)
                ).length === 0 &&
                  isFetchingResponses && (
                    <div className='flex w-full items-center justify-center'>
                      <CircularProgress />
                    </div>
                  )}
              </div>
            </>
          ) : (
            <div className='flex justify-end'>
              {prompt.createdAt > firstManualStepRunCreatedAt ||
              config.allowEditingImportedTurns ? (
                <div className='mr-2 mt-2 flex items-start'>
                  <IconButton onClick={handleEditResponse} className=' text-gray-400'>
                    <EditIcon />
                  </IconButton>
                </div>
              ) : null}

              <div className='max-w-1/2 relative mt-2 mr-1 w-fit min-w-[20%] max-w-[50%] overflow-auto rounded bg-[#F5F5F7] py-1 px-4 text-sm'>
                <span className='absolute right-1 top-0 p-1 text-[8px]'>{promptIndex}</span>
                <Markdown
                  components={{
                    p: ({ children }) => <p className='whitespace-pre-wrap'>{children}</p>,
                  }}>
                  {prompt?.acceptedResponse.trim() as string}
                </Markdown>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export { Turn }
