import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

import { datadogRum } from '@datadog/browser-rum'
import { useProcessById, useStepRunSnooze } from '@invisible/common/components/process-base'
import {
  TBaseRunVariable,
  useWizardState,
} from '@invisible/common/components/providers/active-wizard-provider'
import {
  Backdrop,
  RGLContainer,
  Topbar,
  WizardContainer,
  WizardTitle,
} from '@invisible/common/components/wizard/wizard-container'
import { useLoggedInUser } from '@invisible/hooks/use-logged-in-user'
import { logger } from '@invisible/logger/client'
import { useContext, useQuery } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import { useDisableRefetchOnFocus } from '@invisible/ui/hooks/use-disable-refetch-on-focus'
import {
  CloseIcon,
  DragHandleVerticalIcon,
  StarShineIcon,
  TaskTrainingIcon,
  ThumbsDownIcon,
  ThumbsUpIcon,
} from '@invisible/ui/icons'
import { Modal } from '@invisible/ui/modal'
import { useToasts } from '@invisible/ui/toasts'
import type { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { ManualStepMeta, Wizard as WizardSchemas } from '@invisible/ultron/zod'
import { isEqual } from 'lodash/fp'
import { forwardRef, memo, useEffect, useMemo, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import sanitize from 'sanitize-html'
import { useGate } from 'statsig-react'

import { CustomWizardAtomicComponents } from './customWizardAtomicComponents'
import { WizardAtomicComponents } from './wizardAtomicComponents'
import { WizardTimer } from './WizardTimer'

type TFindAssignedToMeData = inferQueryOutput<'stepRun.findAssignedToMe'>

const allWACs = { ...WizardAtomicComponents, ...CustomWizardAtomicComponents }

const Wizard = () => {
  const { addToast } = useToasts()
  const reactQueryContext = useContext()
  const reactQueryClient = useQueryClient()
  const { state, dispatch } = useWizardState()
  const [currentUser] = useLoggedInUser()

  const [isQaConsentModalOpen, setIsQaConsentModalOpen] = useState(false)
  const [isOffline, setIsOffline] = useState(false)
  const [isAutoSnoozed, seIsAutoSnoozed] = useState(false)

  const isWacSubmitting = Object.values(state.isWacSubmitting).some((value) => value === true)
  const { value: enableTypeMismatch } = useGate('enable-type-mismatch')

  useDisableRefetchOnFocus({ isDisabled: !state.isWizardOpen })
  const [qaStepMeasurement, setQaStepMeasurement] =
    useState<{ belowTheBar: string; atTheBar: string; aboveTheBar: string }>()
  const ref = useRef<HTMLElement>(null)

  const { mutateAsync: snoozeStepRun } = useStepRunSnooze({
    onSettled: () => {
      reactQueryClient.invalidateQueries('get-base-runs')
      reactQueryContext.invalidateQueries('stepRun.findAssignedToMe')
    },
    onSuccess: (stepRun) => {
      reactQueryContext.queryClient.setQueryData<TFindAssignedToMeData | undefined>(
        ['stepRun.findAssignedToMe'],
        (prevData) => {
          if (!prevData) return

          return prevData.map((entry) =>
            entry.stepRuns.some((s) => s.id === stepRun.id)
              ? {
                  ...entry,
                  stepRuns: entry.stepRuns.map((s) =>
                    s.id === stepRun.id ? { ...s, status: 'snoozed' } : s
                  ),
                }
              : entry
          )
        }
      )
    },
  })

  const { data: process } = useProcessById({
    id: state.processId ?? '',
    enabled: !!state.processId,
  })

  const currentStep = useMemo(
    () => process?.steps?.find((s) => s.id === state.stepRun?.stepId),
    [process, state.stepRun?.stepId]
  )

  const onNavigateBackToWizardTab = () => {
    if (document.visibilityState === 'visible') {
      reactQueryClient.invalidateQueries('get-base-runs')
      reactQueryContext.invalidateQueries('stepRun.findAssignedToMe')
    }
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', onNavigateBackToWizardTab)
    return () => document.removeEventListener('visibilitychange', onNavigateBackToWizardTab)
  }, [])

  useEffect(() => {
    // when the wizard is open but it has been snoozed in another tab (only in case of manual snooze, not auto-snooze)
    if (state.isWizardOpen && state.stepRun?.status === 'snoozed' && !isAutoSnoozed) {
      addToast(`Closed the wizard as it has been snoozed in a different tab.`, {
        appearance: 'info',
      })
      dispatch({ type: 'closeWizard' })
      reactQueryClient.invalidateQueries('get-base-runs')
    }
  }, [state.stepRun])

  useEffect(() => {
    if (state.typeMismatches.length > 0 && enableTypeMismatch) {
      logger.error(
        `Type mismatch error in the following fields: ${state.typeMismatches
          .map(
            (mismatch) =>
              `${mismatch.label}: expected ${mismatch.expected}, but got ${mismatch.actual}`
          )
          .join(', ')}`,
        {
          processId: state.processId,
          stepId: state.stepRun?.stepId ?? '',
          stepRunId: state.stepRun?.id ?? '',
          baseRunId: state.baseRun?.id ?? '',
        }
      )
      addToast(
        <div>
          <p>Type mismatch error in the following fields:</p>
          <ul>
            {state.typeMismatches.map((mismatch, index) => (
              <li key={index}>
                <strong>{mismatch.label}</strong>: expected <em>{mismatch.expected}</em>, but got{' '}
                <em>{mismatch.actual}</em>
              </li>
            ))}
          </ul>
        </div>,
        {
          appearance: 'error',
        }
      )
    }
  }, [state.baseRun])

  useEffect(() => {
    if (state.stepRun && currentUser && currentStep) {
      const qaConfig = (currentStep?.meta as ManualStepMeta.TSchema)?.qaConfig

      if (qaConfig) {
        const matchingCondition = (qaConfig.conditionalMeasurementDescriptions ?? []).find(
          (conditionalMeasurementDescription) => {
            const matchingBaseVariable = (
              (state.wizardInitialBRVs as TBaseRunVariable[]) ?? []
            ).find(
              (brv) =>
                brv?.baseVariable.id === conditionalMeasurementDescription.baseVariableId &&
                brv.value === conditionalMeasurementDescription.value
            )

            return !!matchingBaseVariable
          }
        )
        if (matchingCondition)
          setQaStepMeasurement({
            belowTheBar: matchingCondition.belowTheBar ?? '',
            atTheBar: matchingCondition.atTheBar ?? '',
            aboveTheBar: matchingCondition.aboveTheBar ?? '',
          })
        else {
          const generalDescriptions = qaConfig.measurementDescriptions
          setQaStepMeasurement({
            belowTheBar: generalDescriptions?.belowTheBar ?? '',
            atTheBar: generalDescriptions?.atTheBar ?? '',
            aboveTheBar: generalDescriptions?.aboveTheBar ?? '',
          })
        }

        const alreadyDisplayedQaConsent = localStorage.getItem(
          `${currentUser?.id}-${currentStep?.id}-${currentStep?.updatedAt}`
        )

        if (!alreadyDisplayedQaConsent) {
          setIsQaConsentModalOpen(true)
          // clean up the old step run updates consent
          Object.keys(localStorage)
            .filter((x) => x.startsWith(`${currentUser?.id}-${currentStep?.id}-`))
            .forEach((x) => localStorage.removeItem(x))
        }
      } else {
        setQaStepMeasurement(undefined)
      }
    }
  }, [state.stepRun, currentUser, currentStep])
  // Below replaces value: { baseVariableId: [uuid], dynamic: true } by value: [actual value of the baseVariable on the baseRun]
  const parsedWizardConfig = useMemo(
    () =>
      state.wizardData.map((wac) => {
        const value = wac.config.value
        if (WizardSchemas.WACConfig.hasDynamicValue(value)) {
          const baseRunVariable = (state.wizardInitialBRVs as TBaseRunVariable[]).find(
            (brv) => brv.baseVariableId === value.baseVariableId
          )

          return {
            ...wac,
            config: {
              ...wac.config,
              value: baseRunVariable?.value,
              baseRunVariableId: baseRunVariable?.id,
            },
          }
        }
        return wac
      }) as WizardSchemas.WizardConfig.TSchema,
    [state?.wizardInitialBRVs, state.wizardData]
  )

  const handleSnooze = async () => {
    if (state.stepRun?.id) {
      await snoozeStepRun({
        stepRunId: state.stepRun.id,
      })
      dispatch({ type: 'closeWizard' })
    }
  }

  const handleClose = () => {
    dispatch({ type: 'closeWizard' })
    reactQueryContext.invalidateQueries('stepRun.findAssignedToMe')
  }

  useEffect(() => {
    const handleStorageChange = () => {
      const offlineModalData = localStorage.getItem('offline-modal')
      const storedShowOfflineModal = offlineModalData ? JSON.parse(offlineModalData) : false
      setIsOffline(storedShowOfflineModal)

      const idleCheckData = localStorage.getItem('idle-check')
      const storedIdleCheck = idleCheckData ? JSON.parse(idleCheckData) : {}
      seIsAutoSnoozed(storedIdleCheck?.isAutoSnoozed ?? false)
    }

    handleStorageChange()
    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  if (!state.isWizardOpen) return null

  return (
    <Backdrop>
      <WizardContainer>
        <Topbar>
          <WizardTitle>{state.stepName?.toUpperCase()}</WizardTitle>
          <div className='flex items-center gap-2'>
            {state.trainingLink ? (
              <a
                href={state.trainingLink}
                title='Show Me How!'
                target='_blank'
                rel='noreferrer noopener'
                style={{ height: '18px' }}>
                <TaskTrainingIcon
                  color='white'
                  width={45}
                  height={18}
                  style={{ cursor: 'pointer' }}
                />
              </a>
            ) : null}
            <WizardTimer isOffline={isOffline} isAutoSnoozed={isAutoSnoozed} />
            <div className='relative z-50 flex gap-x-2'>
              {qaStepMeasurement ? (
                <StarShineIcon
                  onClick={() => setIsQaConsentModalOpen((prev) => !prev)}
                  className='cursor-pointer text-white'
                  width={20}
                  height={20}
                />
              ) : null}
              <Button
                shape='square'
                size='md'
                variant='secondary'
                icon='SnoozeOutlineIcon'
                color='theme'
                title='Snooze'
                disabled={isWacSubmitting}
                onClick={() => handleSnooze()}
              />
            </div>
            <CloseIcon
              color='white'
              width={18}
              height={18}
              onClick={handleClose}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </Topbar>

        <RGLContainer
          margin={[10, 10]}
          rowHeight={30}
          containerPadding={[20, 10]}
          isDraggable={false}
          isResizable={false}
          draggableHandle='.react-grid-dragHandle-WAC'
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ref={ref}>
          {parsedWizardConfig.map((wac) => (
            <div key={wac.config.id} data-grid={{ ...wac.layout }}>
              <div className='react-grid-dragHandle-WAC absolute  z-50 cursor-grab'>
                <DragHandleVerticalIcon height={12} width={12} />
              </div>
              <MemoizedWAC wac={wac} ref={ref} />
            </div>
          ))}
        </RGLContainer>
      </WizardContainer>
      <Modal
        title='Acknowledge QA Scoring Updates'
        disableClose
        disableClickAway
        primaryButton={
          <Button
            onClick={() => {
              localStorage.setItem(
                `${currentUser?.id}-${currentStep?.id}-${currentStep?.updatedAt}`,
                'true'
              )
              setIsQaConsentModalOpen(false)
            }}>
            Acknowledge Updates
          </Button>
        }
        onClose={() => setIsQaConsentModalOpen(false)}
        visible={isQaConsentModalOpen}>
        <div className='flex max-h-80 max-w-md flex-col gap-y-4 overflow-scroll'>
          <div>
            Changes were made to this Task's QA Scoring we want to make sure you are aware of before
            you continue working.
          </div>
          <div className='flex flex-col gap-y-2'>
            <div className='flex gap-x-1'>
              <div className='text-red-main'>
                <ThumbsDownIcon />
              </div>
              <div className='font-bold'>Below the Bar</div>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: sanitize(qaStepMeasurement?.belowTheBar ?? ''),
              }}
            />
          </div>
          <div className='flex flex-col gap-y-2'>
            <div className='flex gap-x-1'>
              <div className='text-main'>
                <ThumbsUpIcon />
              </div>
              <div className='font-bold'>At the Bar</div>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: sanitize(qaStepMeasurement?.atTheBar ?? ''),
              }}
            />
          </div>
          <div className='flex flex-col gap-y-2'>
            <div className='flex gap-x-1'>
              <div className='text-success-main'>
                <StarShineIcon />
              </div>
              <div className='font-bold'>Above the Bar</div>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: sanitize(qaStepMeasurement?.aboveTheBar ?? ''),
              }}
            />
          </div>
        </div>
      </Modal>
    </Backdrop>
  )
}

const MemoizedWAC = memo(
  forwardRef(({ wac }: { wac: WizardSchemas.WizardConfig.TSchema[number] }, ref) => {
    const WAC = allWACs[wac.config.type]
    const { state } = useWizardState()

    // Overriding default viewname behavior here to capture the Wizard modal in DD RUM
    useEffect(() => {
      if (wac.config.name !== undefined) {
        datadogRum.startView({ name: `Wizard: ${wac.config.name} - ${wac.config.type}` })
      }
    }, [wac.config])

    if (!WAC) return null

    return (
      <WAC
        {...wac.config}
        height={wac.layout.h}
        wizardInitialBRVs={state.wizardInitialBRVs as TBaseRunVariable[]}
        baseRun={state.baseRun}
        stepRun={state.stepRun}
        isReadOnly={false}
        ref={ref}
      />
    )
  }),
  (oldProps, newProps) => isEqual(oldProps.wac, newProps.wac)
)

export { Wizard }
